import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import Home from '../pages/Home';
import About from '../pages/About';
import Services from '../pages/Services';
import CaseStudies from '../pages/CaseStudies';
import Contact from '../pages/Contact';



const Routers =()=> {
  return (
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/Home' element={<Home/>} />
      <Route path='about' element={<About/>} />
      <Route path='services' element={<Services/>} />
      <Route path='contact' element={<Contact/>} />  
      <Route path='casestudies' element={<CaseStudies/>} />    
    </Routes>
  )
}
export default Routers;