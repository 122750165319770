import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/contact.css'
import {Helmet}from"react-helmet";
import ver2 from '../images/Rectangle 9-min.png'

import m5 from '../images/Ellipse 2-min.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

 const Contact =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Contact Us -  Exceptional Customer Service - Verilex</title>
      <meta name="description" content="Get in touch with VeriLex for exceptional customer service. Our team is ready to assist with all inquiries. Contact us today for prompt support and solutions."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section style={{height:"70vh"}} className='header_top' >
      <div className='container_top'>
     <h1>Contact Us </h1>
     <p>We’re Here to Help 24/7</p>
      </div>
    
    </section>

<section className='verix2'>
<div className='verix__div1'>
  <div className='verix__row'>
   <div className='ver__col'>
    <h2>We are available 24/7</h2>
    <br />
    <p>At VeriLex, we’re committed to providing exceptional customer service. Whether you have a question, need assistance, or want to submit a document for review, our team is available 24/7 to assist you.</p>

   </div>
   <div className='ver__col'>
    <img src={ver2} alt="" />
   </div>
  </div>
</div>
</section>
<section className='contact__us'>
<div className='con__div'>
  <div className='con__row'>
  <div className='cont__col'>
    <label htmlFor="">Name:</label>
    <br />
  <input type="text" />
  <br />
  <label htmlFor="">Phone No:</label>
  <br />
  <input type="text" />
  <br />
  <label htmlFor="">Message:</label>
  <br />
  <input style={{height:"30vh"}} type="text" />
  <br /><br />
  <button>Submit</button>
  </div>
  <div className='cont__col'>
  <iframe title='map'style={{height:"60vh",width:"100%"}} className='mapcon' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3464.3943005276515!2d-95.46552238254637!3d29.73729969083219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16c2cf13989%3A0x9fd2bbab78247d62!2s2800%20Post%20Oak%20Blvd%2C%20Houston%2C%20TX%2077056%2C%20USA!5e0!3m2!1sen!2s!4v1675405427489!5m2!1sen!2s"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    
  </div>
  </div>
  
</div>
</section>
<section className='sec5'>
<div className='div__ls'>
  <h2>What Our Clients Say</h2>
  <br />
  <img src={m5} alt="" />
  <br />
  <p>Working with VeriLex has significantly improved our workflow. The accuracy and speed of their document reviews have saved us time and resources.</p>
 <br />
  <h3>Rihanat Oluwadamilola Orire</h3>
  <br />
  <p>RoyBil Ltd.</p>
</div>
</section>
    </div>
  )
}
export default Contact;