import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import logo from './logo2.png';

 const Footer =()=> {
  return (
    <>
      <section className="footer__section">
      <div className="footer__width">
      <div className="row">

        <div className='footer_col' >
        <img src={logo} style={{width:"50%"}} alt="logo"></img>

          <p>We provide reliable, expert legal document review services that let you focus on what matters most—your cases.


 </p>
        </div>
 
        <div className='footer_col'  >
          
        <h3 id='tet' >Pages</h3>

        <a id='tet' href='about' className='link__footer'>About Us</a>
        <a id='tet' href='contact' className='link__footer'>Contact Us</a>
        
        </div>
 
        <div className='footer_col' >
        <h3>Contact</h3>
        <div className='contact_detil'>
        <i class="ri-phone-fill"></i>
        <span>
        <a href="tel:346 387 7187" >&nbsp;&nbsp;346 387 7187</a>
        </span>
      
        </div>
        <div className='contact_detil'>
        <i class="ri-mail-fill"></i>
        <span>  <a href="mailto:info@verilexcorp.com" >&nbsp;&nbsp; info@verilexcorp.com</a>
        </span>
        </div>
        <div className='contact_detil'>
        <i class="ri-linkedin-fill"></i>
        <span>  <a href="https://www.linkedin.com/company/verilex-corp/" >&nbsp;&nbsp; verilex-corp</a>
        </span>
        </div>
        <div className='contact_detil'>
        <i class="ri-map-pin-fill"></i>
        <span>24285 Katy Freeway, Suite 300 Katy TX 77494 </span>
        </div>     

        </div>
 
        </div>
        </div>

      </section> 
    </>
  )
}
export default Footer;