import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import {Helmet}from"react-helmet";
import ver2 from '../images/Rectangle 9-min.png'
import ver3 from '../images/Rectangle 11-min.png'
import m1 from '../images/calendar-min.png'
import m2 from '../images/chess icon-min.png'
import m3 from '../images/client-min.png'
import m4 from '../images/media (4)-min.png'
import m5 from '../images/Ellipse 2-min.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

 const About =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>About Us - VeriLex Corp: Precision & Innovation in Legal Review</title>
      <meta name="description" content="Discover VeriLex Corp: Leaders in precision and innovation for legal review. Learn how our expertise and technology elevate your legal processes."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section style={{height:"70vh"}} className='header_top' >
      <div className='container_top'>
     <h1>About Us </h1>

     <p>Your Trusted Legal Support Partner</p>
      </div>
    
    </section>

<section className='verix2'>
<div className='verix__div1'>
  <div className='verix__row'>
   <div className='ver__col'>
    <p>At VeriLex Corp, we believe in the seamless integration of human expertise and cutting-edge technology to transform legal document review. Our company is founded on the core principles of precision, integrity, and innovation, ensuring that every document we handle receives the highest level of scrutiny and care. By leveraging advanced AI tools alongside our experienced legal professionals, we streamline the review process without compromising on accuracy. VeriLex is dedicated to delivering reliable, efficient legal support that empowers clients to navigate complex legal landscapes with confidence.</p>
 
    <br />
   
   </div>
   <div className='ver__col'>
    <img src={ver2} alt="" />
   </div>
  </div>
</div>
</section>
<section className='sec__abt'>
<div className='div__abt'>
  <div className='abt__row'>
    <div className='abt__col'>
      <img src={m2} alt="" />
      <br />       <br />
      <h2>
      Precision
      </h2>
    </div>
    <div className='abt__col'>
      <img src={m3} alt="" />
      <br />       <br />
      <h2>Integrity</h2>
    </div>
    <div className='abt__col'>
      <img src={m1} alt="" />
      <br />       <br />
      <h2>Innovation </h2>
    </div>

  </div>
</div>
</section>
<section style={{background:"#fff"}} className='verix3'>
  <div className='verix__div1'>
    <div className='verix__row'>

    <div className='ver__col'>
    <h2>How We Do It!</h2>
    <br />
    <p>We utilize a multi-step process to ensure accuracy and compliance in every document we handle. Our team follows strict guidelines to ensure no detail is overlooked, and we pride ourselves on delivering fast, reliable results.</p>

   </div>   <div className='ver__col'>
  <img src={ver3} alt="" />
   </div>

    </div>
  </div>
</section>
<section className='sec5'>
<div className='div__ls'>
  <h2>What Our Clients Say</h2>
  <br />
  <img src={m5} alt="" />
  <br />
  <p>Working with VeriLex has significantly improved our workflow. The accuracy and speed of their document reviews have saved us time and resources.</p>
 <br />
  <h3>Rihanat Oluwadamilola Orire</h3>
  <br />
  <p>RoyBil Ltd.</p>
</div>
</section>
    </div>
  )
}
export default About;