import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import {Helmet}from"react-helmet";
import ver2 from '../images/Rectangle 9-min.png'
import ver3 from '../images/Rectangle 11-min.png'
import m1 from '../images/Image Placeholder (1)-min.png'
import m2 from '../images/tick.png'

import m5 from '../images/Ellipse 2-min.png'
import m1a from '../images/media (1)-min.png'
import m2a from '../images/media (2)-min.png'

import m3a from '../images/cover (1).png'
import m4a from '../images/media (4)-min.png'
import m5a from '../images/Ellipse 2-min.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

 const Services =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Comprehensive Legal Support Services | Verilex Solutions</title>
      <meta name="description" content="Get expert legal support with VeriLex Solutions. Our comprehensive services cover all your legal needs with precision and efficiency. Discover more today!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section style={{height:"70vh"}} className='header_top' >
      <div className='container_top'>
     <h1>Services </h1>
     <p>Comprehensive Legal Services Tailored to Your Needs</p>
      </div>
    
    </section>
    <section style={{background:"#fff"}}>
<div className='verix__div4'>
 
<h2 style={{fontSize:"40px",fontWeight:"300",lineHeight:"50px"}}>At VeriLex, we offer a full suite of legal support services to ensure your law firm operates efficiently. From document reviews to e-discovery, our expert team is ready to take on the heavy lifting, allowing you to focus on winning cases.</h2>
 <br /> <br /> <br />
 <div className='row__2'>
  <div className='col__ve2'>
  <div id='hei'>
    <h3>Legal Document Review</h3>
    <p>We provide thorough, precise document reviews to ensure your case has the strong foundation it needs. Our experienced reviewers meticulously check every detail, giving you peace of mind.
</p>
    </div>
    
    <img src={m1a} alt="" />
  </div>
  <div className='col__ve2'>
  <div id='hei'>
 
    <h3>E-Discovery Support</h3>
    <p>In today’s digital world, managing electronic evidence is key. Our e-discovery service helps you navigate complex data to uncover the critical pieces you need for success.
</p>
 </div>
    <img src={m2a} alt="" />
  </div>
  <div className='col__ve2'>
  <div id='hei'>
 
    <h3>Compliance Monitoring</h3>
    <p>Stay ahead of the ever-evolving regulatory landscape. Our compliance monitoring ensures your legal documents meet current standards, protecting you from costly penalties. 
</p>
</div>
    <img src={m3a} alt="" />
  </div>
  <div className='col__ve2'>
  <div id='hei'>
  <h3>Customized Solutions</h3>
    <p>Every law firm is unique, and so are our solutions. We work closely with you to design services that meet your specific needs, ensuring seamless integration into your workflow
</p>
 
 </div>
    <img src={m4a} alt="" />
  </div>
 </div>
</div>
</section>
<section className='verix3'>
  <div className='verix__div1'>
    <div className='verix__row'>
   <div className='ver__col'>
  <img src={ver3} alt="" />
   </div>
   <div className='ver__col'>
    <h2>How We Do It!</h2>
    <br />
    <p>Our approach is simple: we deliver high-quality work with efficiency and integrity. Each document passes through multiple stages of review, guaranteeing accuracy. Our team stays updated on legal trends to ensure your documents remain compliant and up-to-date.</p>
   </div>
    </div>
  </div>
</section>

<section style={{display:"none"}} className='serice__sec'>
<div className='service__sec__div'>
  <div className='service__row'>
    <div className='service__cola'>
      <h2>Why Choose Us?</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed facilisis eleifend quam, non efficitur nisi mattis quis. Vivamus scelerisque orci nec erat cursus, sed facilisis velit porttitor. Vestibulum ligula sapien, cursus sed consectetur nec, tincidunt ac metus. Vivamus accumsan diam eget auctor.</p>
<br /><br />
    <div className='service__row2'>
   <div className='service__colc'>
    <img src={m2} alt="" />
   </div>
   <div className='service__cold'>
    <h2>Sustainable Practices</h2>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed facilisis eleifend quam, non efficitur nisi mattis quis. Vivamus scelerisque orci nec erat cursus, sed facilisis velit porttitor. Vestibulum ligula sapien, cursus sed consectetur.</p>
    <h3>Read More...</h3>
   </div>

    </div>
    <br />

    <div className='service__row2'>
   <div className='service__colc'>
    <img src={m2} alt="" />
   </div>
   <div className='service__cold'>
    <h2>Sustainable Practices</h2>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed facilisis eleifend quam, non efficitur nisi mattis quis. Vivamus scelerisque orci nec erat cursus, sed facilisis velit porttitor. Vestibulum ligula sapien, cursus sed consectetur.</p>
    <h3>Read More...</h3>
   </div>

    </div>
    
    </div>
    <div className='service__colb'>
      <img src={m1} alt="" />
    </div>
  </div>
</div>
</section>

<section className='sec5'>
<div className='div__ls'>
  <h2>What Our Clients Say</h2>
  <br />
  <img src={m5} alt="" />
  <br />
  <p>Working with VeriLex has significantly improved our workflow. The accuracy and speed of their document reviews have saved us time and resources.</p>
 <br />
  <h3>Rihanat Oluwadamilola Orire</h3>
  <br />
  <p>RoyBil Ltd.</p>
</div>
</section>
    </div>
  )
}
export default Services;