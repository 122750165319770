import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/Case.css'
import {Helmet}from"react-helmet";
import ver2 from '../images/Rectangle 9-min.png'
import ver3 from '../images/Rectangle 11-min.png'
import m1 from '../images/Image Placeholder (1)-min.png'
import m2 from '../images/tick.png'

import m5 from '../images/Ellipse 2-min.png'
import grp from '../images/Group 4-min.png'

import AOS from 'aos';
import 'aos/dist/aos.css';

 const CaseStudies =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Success Stories - Legal Support Services to Lawyers, Law Firms & Businesses</title>
      <meta name="description" content="Discover Legal Support World s success stories and learn how we have helped businesses streamline their legal operations and achieve their goals."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section style={{height:"70vh"}} className='header_top' >
      <div className='container_top'>
     <h1> Case Studies</h1>
     <p>Real-World Results for Our Clients</p>
      </div>
    
    </section>

    <br /><br /><br />
<section >
<div className='cas__div'>
<div className='cas__row'>
  <div className='cas__col'>
    <h3>Case Study 1:</h3>
    <h2>Automations for a Fortune 500 Company</h2>
    <p>VeriLex helped a Fortune 500 company automate its legal document review process, saving the firm 20 hours per week. With our comprehensive e-discovery support, the company was able to reduce its review time and improve case outcomes.</p>
   <br />
    <img src={grp} alt="" />
  </div>
  <div className='cas__col'>
    <h3>Case Study 2:</h3>
    <h2>Enhancing Client Satisfaction for a Boutique Law Firm</h2>
    <p>A boutique law firm was struggling with document review bottlenecks. We implemented a custom review process that reduced turnaround time by 50%, improving overall client satisfaction and retention.</p>
    <img src={grp} alt="" />
  </div>
  <div className='cas__col'>
    <h3>Case Study 3:</h3>
    <h2>Ensuring Compliance for a Multi-National Corporation</h2>
    <p>A multi-national corporation faced challenges with regulatory compliance across multiple jurisdictions. VeriLex’s compliance monitoring services ensured that all documents were updated to meet international standards, helping the firm avoid costly fines.</p>
    <img src={grp} alt="" />
  </div>
  
</div>
</div>
</section>
<br />
<section className='verix3'>
  <div className='verix__div1'>
    <div className='verix__row'>
   <div className='ver__col'>
  <img src={ver3} alt="" />
   </div>
   <div className='ver__col'>
    <h2>How We Do It!</h2>
    <br />
    <p>Our case studies reflect our commitment to excellence. Each case is handled with care, ensuring a tailored solution for every client’s needs. By leveraging the latest technology and industry knowledge, we help firms achieve their goals efficiently.</p>
   
   </div>
    </div>
  </div>
</section>

<section style={{display:"none"}} className='serice__sec'>
<div className='service__sec__div'>
  <div className='service__row'>
    <div className='service__cola'>
      <h2>Why Choose Us?</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed facilisis eleifend quam, non efficitur nisi mattis quis. Vivamus scelerisque orci nec erat cursus, sed facilisis velit porttitor. Vestibulum ligula sapien, cursus sed consectetur nec, tincidunt ac metus. Vivamus accumsan diam eget auctor.</p>
<br /><br />
    <div className='service__row2'>
   <div className='service__colc'>
    <img src={m2} alt="" />
   </div>
   <div className='service__cold'>
    <h2>Sustainable Practices</h2>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed facilisis eleifend quam, non efficitur nisi mattis quis. Vivamus scelerisque orci nec erat cursus, sed facilisis velit porttitor. Vestibulum ligula sapien, cursus sed consectetur.</p>
    <h3>Read More...</h3>
   </div>

    </div>
    <br />

    <div className='service__row2'>
   <div className='service__colc'>
    <img src={m2} alt="" />
   </div>
   <div className='service__cold'>
    <h2>Sustainable Practices</h2>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed facilisis eleifend quam, non efficitur nisi mattis quis. Vivamus scelerisque orci nec erat cursus, sed facilisis velit porttitor. Vestibulum ligula sapien, cursus sed consectetur.</p>
    <h3>Read More...</h3>
   </div>

    </div>
    
    </div>
    <div className='service__colb'>
      <img src={m1} alt="" />
    </div>
  </div>
</div>
</section>

<section className='sec5'>
<div className='div__ls'>
  <h2>What Our Clients Say</h2>
  <br />
  <img src={m5} alt="" />
  <br />
  <p>Working with VeriLex has significantly improved our workflow. The accuracy and speed of their document reviews have saved us time and resources.</p>
 <br />
  <h3>Rihanat Oluwadamilola Orire</h3>
  <br />
  <p>RoyBil Ltd.</p>
</div>
</section>
    </div>
  )
}
export default CaseStudies;