import React, { useEffect } from 'react'
import { motion } from "framer-motion"
import '../components/Styles/home.css'
import { Helmet } from "react-helmet";
import ver2 from '../images/Rectangle 9-min.png'
import ver3 from '../images/Rectangle 11-min.png'
import m1 from '../images/media (1)-min.png'
import m2 from '../images/media (2)-min.png'
import m3 from '../images/cover (1).png'
import m4 from '../images/media (4)-min.png'
import vec from '../images/Vector (19)-min.png'
import m1a from '../images/calendar-min.png'
import m2a from '../images/chess icon-min.png'
import m3a from '../images/client-min.png'
import m5 from '../images/Ellipse 2-min.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
  useEffect(() => {
    AOS.init();
  })
  return (
    <div>
      <Helmet>

        <title>Efficient Legal Document Review in USA| Verilex</title>
        <meta name="description" content="Streamline your legal document review with Verilex. Expert services for efficient, accurate, and reliable reviews across the USA. Get started today!" />
        < meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charset="UTF-8" />

      </Helmet>
      <section className='header_top' >
        <div className='container_top'>
          <div style={{ height: "80vh" }}>
            <h1>Redefining Legal Document Review, One Page at a Time</h1>
            <br />
            <p>We provide reliable, expert legal document review services that let you focus on what matters most—your cases.</p>
            <br />
            <button>
               <a href="contact">Contact Us</a>
        </button>
          </div>
          <div id='sh' className='div__abt'>
            <div className='abt__row'>
              <div className='abt__col'>
                <img src={m2a} alt="" />
                <br />       <br />
                <h2>Precision</h2>
              </div>

              &nbsp;    &nbsp;    <div className='abt__col'>
                <img src={m3a} alt="" />
                <br />       <br />
                <h2>Integrity</h2>
              </div>
              &nbsp;    &nbsp;
              <div className='abt__col'>
                <img src={m1a} alt="" />
                <br />       <br />
                <h2>
                  Innovation</h2>
              </div>

            </div>
          </div>
        </div>

      </section>
      <section id='sh1' className='sec__abt'>
        <div className='div__abt'>
          <div className='abt__row'>
            <div className='abt__col'>
              <img src={m1a} alt="" />
              <br />       <br />
              <h2>Precision</h2>
            </div>
            <br />
            <div className='abt__col'>
              <img src={m3a} alt="" />
              <br />       <br />

              <h2>Integrity</h2>
            </div>
            <br />
            <div className='abt__col'>
              <img src={m2a} alt="" />
              <br />       <br />
              <h2>
                Innovation</h2>    </div>

          </div>
        </div>
      </section>
      <section className='verix2'>

        <div className='verix__div1'>
          <div className='verix__row'>
            <div className='ver__col'>
              <h2>Stop Chasing Paperwork!</h2>
              <br />
              <p>Let us take care of your legal documents. At VeriLex, we specialize in providing fast and accurate document reviews to ensure you never miss a critical detail. Our team of legal experts makes document management seamless, allowing you to focus on representing your clients with confidence.</p>
              <br />
              <button>
                <a href='about'>More About Us</a>

              </button>
            </div>
            <div className='ver__col'>
              <img src={ver2} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className='verix3'>
        <div className='verix__div1'>
          <div className='verix__row'>
            <div className='ver__col'>
              <img style={{ width: "100%" }} src={ver3} alt="" />
            </div>
            <div className='ver__col' >

              <div style={{ background: "", padding: "20px" }}>
                <h2 style={{ fontSize: "40px" }}>Why Choose VeriLex for Legal Document Review?
                </h2>
                <br />

                <div className='row__sign'>
                  <div className='sign__cola'>
                    <img src={vec} alt="" />
                  </div>
                  <div className='sign__colb'>

                    <p >AI-powered document review for faster, more accurate results.
                    </p>

                  </div>

                </div>
                <br />
                <div className='row__sign'>
                  <div className='sign__cola'>
                    <img src={vec} alt="" />
                  </div>
                  <div className='sign__colb'>

                    <p>Skilled legal professionals ensure thorough and reliable analysis.
                    </p>
                  </div>

                </div>
                <br />
                <div className='row__sign'>
                  <div className='sign__cola'>
                    <img src={vec} alt="" />
                  </div>
                  <div className='sign__colb'>

                    <p>Comprehensive services: contract review, compliance, &  e-discovery.
                    </p>

                  </div>

                </div>
                <br />
                <div className='row__sign'>
                  <div className='sign__cola'>
                    <img src={vec} alt="" />
                  </div>
                  <div className='sign__colb'>

                    <p>Secure file handling with advanced data protection.</p>

                  </div>

                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ backgroundColor: "#fff" }}>
        <div className='div__three'>

          <h2 style={{ textAlign: "center", fontWeight: "bold", fontSize: "40px" }}>Our Three-Step Document Review Process</h2>
          <br />
          <p style={{ textAlign: "center" }}>At VeriLex, we combine human expertise with AI-powered tools to deliver efficient and precise legal document review services. Our process ensures thoroughness at every stage, providing our clients with the highest standard of quality.</p>
          <br /><br />
          <div className='row__sign'>
            <div className='sign__cola'>
              <img src={vec} alt="" />
            </div>
            <div className='sign__colb'>

              <h3>Step 1: Document Intake and Categorization</h3>
              <br />
              <p>Upon receipt, documents are first reviewed by our *paralegals* who categorize and prioritize them based on case relevance, urgency, and complexity. Leveraging advanced AI tools, we streamline this process by pre-processing documents, identifying key clauses, terms, and relevant sections, ensuring that nothing important is missed.</p>

            </div>

          </div>
          <br /><br />
          <div className='row__sign'>
            <div className='sign__cola'>
              <img src={vec} alt="" />
            </div>
            <div className='sign__colb'>

              <h3>Step 2: Detailed Review by Paralegals and Attorneys</h3>
              <br />
              <p>Once categorized, our *paralegals* conduct a comprehensive review, analyzing the documents for compliance, risks, and key insights. *Attorneys* then oversee the process, applying their legal expertise to ensure that every aspect is thoroughly examined. AI further enhances this step by cross-referencing documents with legal databases, identifying inconsistencies, and flagging critical issues for review.</p>
            </div>

          </div>
          <br /><br />
          <div className='row__sign'>
            <div className='sign__cola'>
              <img src={vec} alt="" />
            </div>
            <div className='sign__colb'>

              <h3>Step 3: Final Review and Quality Assurance</h3>
              <br />
              <p>After the detailed review, our *attorneys* conduct a final assessment, making necessary adjustments and compiling a comprehensive report for the client. Our AI tools provide an additional quality check, verifying details and generating a summarized report, which our *attorneys* approve before delivery. This ensures that our clients receive accurate, reliable, and actionable legal insights.</p>  </div>

          </div>
          <br /><br />
          <button className='btnn2'> <a href="contact">Contact Us</a></button>

        </div>

      </section>
      <section style={{ background: "#fff" }}>
        <div className='verix__div4'>

          <h2 style={{ fontSize: "40px", fontWeight: "300", lineHeight: "50px" }}>At VeriLex, we offer a full suite of legal support services to ensure your law firm operates efficiently. From document reviews to e-discovery, our expert team is ready to take on the heavy lifting, allowing you to focus on winning cases.</h2>
          <br /> <br /> <br />
          <div className='row__2'>
            <div className='col__ve2'>
              <div id='hei'>
                <h3>Legal Document Review</h3>
                <p>We provide thorough, precise document reviews to ensure your case has the strong foundation it needs. Our experienced reviewers meticulously check every detail, giving you peace of mind.
                </p>
              </div>

              <img src={m1} alt="" />
            </div>
            <div className='col__ve2'>
              <div id='hei'>

                <h3>E-Discovery Support </h3>
                <p>In today’s digital world, managing electronic evidence is key. Our e-discovery service helps you navigate complex data to uncover the critical pieces you need for success.
                </p>
              </div>
              <img src={m2} alt="" />
            </div>
            <div className='col__ve2'>
              <div id='hei'>

                <h3>Compliance Monitoring</h3>
                <p>Stay ahead of the ever-evolving regulatory landscape. Our compliance monitoring ensures your legal documents meet current standards, protecting you from costly penalties.
                </p>
              </div>
              <div> <img src={m3} alt="" /></div>

            </div>
            <div className='col__ve2'>
              <div id='hei'>
                <h3>Customized Solutions</h3>
                <p>Every law firm is unique, and so are our solutions. We work closely with you to design services that meet your specific needs, ensuring seamless integration into your workflow
                </p>

              </div>
              <img src={m4} alt="" />
            </div>
          </div>


        </div>
      </section>

      <section className='sec5'>
        <div className='div__ls'>
          <h2>What Our Clients Say</h2>
          <br />
          <img src={m5} alt="" />
          <br />
          <p>Working with VeriLex has significantly improved our workflow. The accuracy and speed of their document reviews have saved us time and resources.</p>
          <br />
          <h3>Rihanat Oluwadamilola Orire</h3>
          <br />
          <p>RoyBil Ltd.</p>
        </div>
      </section>
    </div>
  )
}
export default Home;